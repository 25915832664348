const self = window as any;
export function initMonacoEnv() {
  self.MonacoEnvironment = {
    getWorker() {
      const WorkerH = new Worker(new URL('monaco-editor/esm/vs/language/html/html.worker', import.meta.url));
      return WorkerH
    },
  };
  
  self.XmllintWASMEnvironment = {
    getWorker() {
      const WorkerX = new Worker(new URL('@platform/xmllint-wasm/dist/xmllint-browser', import.meta.url));
      return WorkerX
    },
  };
}