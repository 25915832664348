import App from "./App.vue";
import router from "./router";
import { createApp } from "vue";
import { Router } from "vue-router";
import { caasMicroApp } from "@platform/common";
import { initMonacoEnv } from "@/utils/monaco-env";
new caasMicroApp().start({ 
  tagName: "micro-app-caas",
  "disable-memory-router": true,
  "disable-patch-request": true,
});
declare global {
  interface Window {
    shellBaseConfig: any;
  }
}
initMonacoEnv();
const Routers: Router = router;
const CaasApp = createApp(App);
CaasApp.use(Routers);
CaasApp.mount("#us-leg-shell");
